import { Suspense } from "react";
import Footer from "./helpers/Footer";
import Workspace from "./workspace/Workspace";

function App() {
  return (
    <>
      <Suspense fallback="loading...">
        <div id="wrapper" className="has-background-light">
          <Workspace />
        </div>

        <Footer />
      </Suspense>
    </>
  );
}

export default App;
