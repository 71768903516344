import { useContext } from "react"
import { Link } from "react-router-dom"
import { DataContext } from "../../context/DataContext"
import { growTree, nilObjectID, responsiveContentSize } from "../../utils"

const MainList = () => {

    const { dataOptions, categories } = useContext(DataContext)

    const seriesWithCats = growTree(
        [],
        [...dataOptions, ...categories],
        0,
        0,
        nilObjectID,
        [],
        true
    )
    return (
        <div className="columns is-centered">
            <div className={`column ${responsiveContentSize}`}>
                <div className="box">

                    {seriesWithCats.map(item => {
                        return (
                            <>
                                <label htmlFor="" className="label">
                                    {
                                        item.type === "series"
                                            ? <Link
                                                to={`/main/${item.value}`}
                                            // onClick={() => setCurSeriesID(item.value)}
                                            >{item.label}</Link>
                                            : item.label}

                                </label>
                            </>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default MainList