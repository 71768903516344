import { Navigate, Outlet, Route, Routes } from "react-router-dom"
import { responsiveContentSize, responsiveMenuSize } from "../../utils"
import CategoryView from "./categories/CategoryView"
import DataMenu from "./DataMenu"
import ModelRunView from "./models/ModelRunView"
import ModelUpdateView from "./models/ModelUpdateView"
import DataUpdate from "./series/DataUpdate"

const DataView = () => {
    return (
        <>
            <Outlet />
            <div className="columns is-desktop">
                <div className={`column ${responsiveMenuSize}`}>
                    {/* <div className="box"> */}
                    <DataMenu />
                    {/* </div> */}
                </div>
                <div className={`column ${responsiveContentSize}`}>

                    <Routes>
                        <Route path="" element={<Navigate to={"update"} />} />
                        <Route path="update" element={<DataUpdate />} />
                        <Route path="categories" element={<CategoryView />} />

                        <Route path="models" element={<Navigate to={"update"} />} />
                        <Route path="models/update" element={<ModelUpdateView />} />
                        <Route path="models/run" element={<ModelRunView />} />

                    </Routes>
                </div>
            </div>



        </>
    )
}

export default DataView