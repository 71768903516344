import { useContext } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { UserContext } from "../context/UserContext"

const NavBarSections = () => {

    const { t } = useTranslation()
    const { user: { role } } = useContext(UserContext)

    var sections = ["main", "blog"]


    if (role === "admin") {
        sections.push("users")
        sections.push("data")
    }
    return sections.map(section => {
        return (
            <Link key={section} className="navbar-item" to={section}>
                {t(`navbar.${section}`)}
            </Link>
        )
    })
}

export default NavBarSections