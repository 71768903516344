import axios from "axios"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { errorMessage } from "../errors"
import { API_URL } from "../utils"

const useDataOptions = () => {

    const [data, setData] = useState([])
    const [options, setOptions] = useState([])
    const [loading, setLoading] = useState(false)
    const [needRefetch, setNeedRefetch] = useState(true)

    const fetchOptions = async () => {

        setLoading(true)
        try {

            const resp = await axios.get(`${API_URL}/data/list`)

            const {
                status,
                message,
                data: serverData
            } = resp.data

            if (status === "failed") {
                setLoading(false)
                return toast.error(errorMessage(message))
            }

            setData(serverData.reduce((prev, cur) => ({ ...prev, [cur.id]: cur }), {}))


            const dataOptions = serverData.map(item => {
                return {
                    label: item.name,
                    value: item.id,
                    id: item.id,
                    titleRu: item.name,
                    titleEn: item.name,
                    parentID: item.category,
                    type: "series"
                }
            }).sort((a, b) =>
                a.label.localeCompare(b.label)
            )


            setOptions(dataOptions)



        } catch (error) {

            setLoading(false)
            toast.error(errorMessage("sw:::000"))
        }


        setLoading(false)
    }


    useEffect(() => {
        if (needRefetch) {
            setNeedRefetch(false)
            setLoading(true)
            fetchOptions()
        }
        // eslint-disable-next-line
    }, [needRefetch])

    return {
        data,
        options,
        loading,
        setNeedRefetch
    }


}

export default useDataOptions