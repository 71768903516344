import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import { BrowserRouter } from "react-router-dom";
import App from "./App";

import './i18n';
import "react-toastify/dist/ReactToastify.min.css"
import "font-awesome/css/font-awesome.min.css"
import { ToastContainer } from "react-toastify";
import UserContextProvider from "./context/UserContext";
import DataContextProvider from "./context/DataContext";
import dayjs from "dayjs";


var utc = require('dayjs/plugin/utc')
dayjs.extend(utc)

const root = ReactDOM.createRoot(document.getElementById("root"));




root.render(
  // <React.StrictMode>

  <BrowserRouter
    getUserConfirmation={(message, callback) => {
      const allowTransition = window.confirm(message);
      window.setTimeout(() => {
        callback(allowTransition);
      }, 1000);
    }}
  >

    <UserContextProvider>
      <DataContextProvider>
        <App />
      </DataContextProvider>
    </UserContextProvider>

    <ToastContainer
      position="bottom-center"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable={false}
      pauseOnHover
    />
  </BrowserRouter>


  // </React.StrictMode>
);
