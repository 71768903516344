import axios from "axios"
import { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { DataContext } from "../../../context/DataContext"
import { errorMessage } from "../../../errors"
import Select from "../../../helpers/Select"
import { API_URL, isEmptyString, nilObjectID } from "../../../utils"

const CategoryNew = () => {

    const { categoriesOptions, categoriesLoading, setNeedRefetchCategories } = useContext(DataContext)
    const { t } = useTranslation()

    const [titleRu, setTitleRu] = useState("")
    const [titleEn, setTitleEn] = useState("")
    const [parent, setParent] = useState({})

    const [redTitleRu, setRedTitleRu] = useState("")
    const [redTitleEn, setRedTitleEn] = useState("")

    const [loading, setLoading] = useState(false)

    const createCategory = async () => {


        if (isEmptyString(titleRu)) {
            setRedTitleRu(true)
            return toast.error(errorMessage("ca:::001"))
        }

        if (isEmptyString(titleEn)) {
            setRedTitleEn(true)
            return toast.error(errorMessage("ca:::001"))
        }

        setLoading(true)

        const data = {
            titleRu,
            titleEn,
        }

        data["parentID"] = parent ? parent.value : nilObjectID


        try {
            const resp = await axios.post(`${API_URL}/category/create`, data)

            const { status, message } = resp.data

            if (status === "failed") {
                setLoading(false)
                return toast.error(errorMessage(message))
            }
        } catch (error) {
            setLoading(false)
        }

        setNeedRefetchCategories(true)
        setTitleRu("")
        setTitleEn("")
        setParent(null)
        setLoading(false)
        toast.success("category.createSuccess")
    }


    return (
        <div className="box">
            <div className="form">

                <div className="columns">
                    <div className="column">
                        <label className="label">
                            {t("category.titleRu")}
                        </label>
                        <input className={`input is-small ${redTitleRu ? "is-danger" : ""}`}
                            value={titleRu}
                            onChange={e => setTitleRu(e.target.value)} />
                    </div>
                    <div className="column">
                        <label className="label">
                            {t("category.titleEn")}
                        </label>
                        <input className={`input is-small ${redTitleEn ? "is-danger" : ""}`}
                            value={titleEn}
                            onChange={e => setTitleEn(e.target.value)} />

                    </div>
                </div>

                <label className="label">
                    {t("category.parentCategory")}
                </label>
                <div className="field">
                    <div className="control">
                        <Select
                            options={categoriesOptions}
                            isSmall={true}
                            value={parent}
                            onChange={target => setParent({
                                label: target.label.trim(),
                                value: target.value
                            })}
                            isLoading={categoriesLoading}
                            isDisabled={categoriesLoading}

                        />
                    </div>
                </div>
                <div className="field">
                    <div className="control">

                        <div className="columns is-centered">
                            <div className="column is-half">
                                <button className={`button is-accent-2 is-fullwidth ${loading ? "is-loading" : ""}`}
                                    onClick={() => createCategory()}>
                                    {t("category.create")}
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default CategoryNew