import { useTranslation } from "react-i18next"
import { TailSpin } from "react-loader-spinner"

const Spinner = ({ text }) => {
  const { t } = useTranslation()

  return (
    <div className="columns is-centered">
      <div className="column is-narrow">
        <TailSpin color="hsl(0, 0%, 48%)" height={100} width={100} />
        <p className="title has-text-grey is-5 mt-4">
          {t("loading")} {text}
        </p>
      </div>
    </div>
  )
}

export default Spinner
