

import NavBar from "./NavBar";
import RestrictedArea from "./areas/RestrictedArea";
import SystemArea from "./areas/SystemArea";
import UnrestrictedArea from "./areas/UnrestrictedArea";
import NavBarSections from "./NavBarSections";
import { useContext } from "react";
import { UserContext } from "../context/UserContext";

const Workspace = () => {

    const { user: { role } } = useContext(UserContext)

    return (

        <>
            <NavBar>
                <NavBarSections />
            </NavBar>
            <div className="columns">
                <div className="column is-12">
                    <div className="container">
                        <div className="section">

                            <UnrestrictedArea />

                            {(role === "admin") || (role === "subscriber") ? <RestrictedArea /> : null}

                            {role === "admin" ? <SystemArea /> : null}

                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default Workspace;
