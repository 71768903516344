
import ModelRun from "./ModelRun"
import TaskList from "./TaskList"

const ModelRunView = () => {

    return (
        <>

            <ModelRun />

            <TaskList />
        </>
    )
}

export default ModelRunView