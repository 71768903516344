import { createContext, useState } from "react";
import useUsers from "./useUsers";


export const UsersContext = createContext()

const UsersContextProvider = ({ children }) => {
    const [page, setPage] = useState(1)

    const {
        users,
        loading: usersLoading,
        setNeedRefetch: setNeedRefetchUsers,

        pageCount
    } = useUsers(page, setPage)



    const value = {
        users,
        usersLoading,
        setNeedRefetchUsers,

        page,
        setPage,
        pageCount
    }


    return <UsersContext.Provider value={value}>{children}</UsersContext.Provider>
}

export default UsersContextProvider