import axios from "axios"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { errorMessage } from "../errors"
import { API_URL } from "../utils"

const useUsers = (page, setPage) => {


    const [users, setUsers] = useState([])
    const [loading, setLoading] = useState(false)
    const [needRefetch, setNeedRefetch] = useState(true)

    const [pageCount, setPageCount] = useState(0)


    const fetchUsers = async () => {
        setLoading(true)
        try {
            const resp = await axios.get(`${API_URL}/user/list?page=${page}`)

            const {
                status,
                message,
                users: usersList,
                count

            } = resp.data

            if (status === "failed") {
                setLoading(false)
                return toast.error(errorMessage(message))
            }

            setPageCount(count)


            setUsers(usersList.reduce((prev, cur) => {
                return {
                    ...prev, [cur.id]: cur
                }
            }, {}))





        } catch (error) {
            setLoading(false)
            toast.error(errorMessage("sw:::000"))
        }

        setLoading(false)
    }

    useEffect(() => {

        if (needRefetch) {
            setPage(1)
            setNeedRefetch(false)
            setLoading(true)
            fetchUsers()
        }
        // eslint-disable-next-line
    }, [needRefetch])

    useEffect(() => {
        fetchUsers()
        setNeedRefetch(false)
        // eslint-disable-next-line
    }, [page])


    return {
        users, loading, setNeedRefetch, pageCount
    }


}

export default useUsers