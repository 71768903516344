import axios from "axios"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { errorMessage } from "../../../errors"
import { API_URL, isEmptyString } from "../../../utils"

const FileUpdate = () => {

    const { t } = useTranslation()

    // const [key, setKey] = useState(Math.random().toString())

    const [redFile, setRedFile] = useState(false)

    const [file, setFile] = useState({ name: "" })

    const [loading, setLoading] = useState(false)


    const updateData = async () => {

        if (isEmptyString(file?.name)) {
            setRedFile(true)
            return toast.error(errorMessage("da:::001"))
        }

        if (!file?.name?.endsWith(".csv")) {
            setRedFile(true)
            return toast.error(errorMessage("da:::002"))

        }

        setLoading(true)

        const formData = new FormData()
        formData.append("file", file)

        try {
            const resp = await axios.post(`${API_URL}/data/update`, formData)

            const { status, message } = resp.data

            if (status === "failed") {
                setLoading(false)
                return toast.error(errorMessage(message))
            }
        } catch (error) {
            setLoading(false)
        }

        setFile({ name: "" })
        setLoading(false)
    }
    return (
        <div className="box">

            <div className="form has-text-centered">
                <div className="field">
                    <div
                        className={`file is-centered is-boxed ${redFile ? "is-danger" : ""
                            }`}
                    >
                        <label className="file-label">
                            <input
                                className="file-input"
                                type="file"
                                name="file"
                                onChange={e => {
                                    setFile(e.target.files[0])
                                }}
                            // key={key}

                            />
                            <span className="file-cta">
                                <span className="file-icon">
                                    <i className="fa fa-upload"></i>
                                </span>
                                <span className="file-label">{`${file?.name?.length ? file?.name : t("files.chooseFile")
                                    }`}</span>
                            </span>


                        </label>
                    </div>
                </div>

                <div className="control">
                    <button
                        className={`button is-fullwidth is-small is-accent-2 ${loading ? "is-loading" : ""}`}
                        onClick={() => updateData()}
                    >
                        {t("data.update")}
                    </button>
                </div>

            </div >
        </div>
    )
}

export default FileUpdate