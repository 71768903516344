import axios from "axios"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { errorMessage } from "../errors"
import { API_URL, growTree, nilObjectID } from "../utils"

const useCategories = () => {


    const [categories, setCategories] = useState([])
    const [loading, setLoading] = useState(false)
    const [needRefetch, setNeedRefetch] = useState(true)

    const [categoriesOptions, setCategoriesOptions] = useState([])

    const fetchCategories = async () => {
        setLoading(true)
        try {
            const resp = await axios.get(`${API_URL}/category/list`)

            const {
                status,
                message,
                categories: categoriesList
            } = resp.data

            if (status === "failed") {
                setLoading(false)
                return toast.error(errorMessage(message))
            }

            const typedCategories = categoriesList.map(item => {
                item.type = "category"

                return item
            })

            setCategories(typedCategories)

            setCategoriesOptions(
                growTree(
                    [], categoriesList, 0, 0, nilObjectID
                )
            )




        } catch (error) {
            setLoading(false)
            toast.error(errorMessage("sw:::000"))
        }

        setLoading(false)
    }

    useEffect(() => {
        if (needRefetch) {
            setNeedRefetch(false)
            setLoading(true)
            fetchCategories()
        }
        // eslint-disable-next-line
    }, [needRefetch])

    return {
        categories, loading, setNeedRefetch, categoriesOptions
    }


}

export default useCategories