import FileUpdate from "./FileUpdate"
import SeriesUpdate from "./SeriesUpdate"

const DataUpdate = () => {
    return (
        <div>
            <FileUpdate />

            <SeriesUpdate />
        </div>
    )
}

export default DataUpdate