import axios from "axios"
import { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { DataContext } from "../../../context/DataContext"
import { errorMessage } from "../../../errors"
import Select from "../../../helpers/Select"
import { API_URL, growTree, isEmptyString, nilObjectID } from "../../../utils"

const CategoryUpdate = () => {

    const { categories, categoriesLoading, setNeedRefetchCategories } = useContext(DataContext)

    const [update, setUpdate] = useState(null)

    const updateCategoriesOptions = growTree(
        [], categories, 0, 0, nilObjectID
    )



    const [parentCategoriesOptions, setParentCategoriesOptions] = useState([])

    const { t } = useTranslation()

    const [titleRu, setTitleRu] = useState("")
    const [titleEn, setTitleEn] = useState("")
    const [parent, setParent] = useState(null)

    const [redTitleRu, setRedTitleRu] = useState("")
    const [redTitleEn, setRedTitleEn] = useState("")

    const [loading, setLoading] = useState(false)

    const [keyParent, setKeyParent] = useState(Math.random().toString())
    const [keyUpdate, setKeyUpdate] = useState(Math.random().toString())

    const createCategory = async () => {


        if (isEmptyString(titleRu)) {
            setRedTitleRu(true)
            return toast.error(errorMessage("ca:::001"))
        }

        if (isEmptyString(titleEn)) {
            setRedTitleEn(true)
            return toast.error(errorMessage("ca:::001"))
        }

        setLoading(true)

        const data = {
            id: update.value,
            titleRu,
            titleEn,
        }

        data["parentID"] = parent ? parent.value : nilObjectID


        try {
            const resp = await axios.post(`${API_URL}/category/update`, data)

            const { status, message } = resp.data

            if (status === "failed") {
                setLoading(false)
                return toast.error(errorMessage(message))
            }
        } catch (error) {
            setLoading(false)
        }

        setNeedRefetchCategories(true)
        setTitleRu("")
        setTitleEn("")
        setParent(null)
        setUpdate(null)
        setKeyParent(Math.random.toString())
        setKeyUpdate(Math.random.toString())
        setLoading(false)
        toast.success("category.createSuccess")
    }


    return (
        <div className="box">
            <div className="form">

                <label className="label">
                    {t("category.updateCategory")}
                </label>
                <div className="field">
                    <div className="control">
                        <Select
                            options={updateCategoriesOptions}
                            isSmall={true}
                            value={update}
                            key={keyUpdate}
                            onChange={target => {
                                setUpdate({
                                    label: target.label.trim(),
                                    value: target.value
                                })
                                setTitleRu(target.titleRu)
                                setTitleEn(target.titleEn)

                                const curParentOptions = growTree(
                                    [], categories, 0, 0, nilObjectID, target.value
                                )
                                setParentCategoriesOptions(curParentOptions)

                                const curParent = curParentOptions?.filter(item => item.value === target.parentID)?.[0]


                                if (curParent) {

                                    setParent({
                                        ...curParent, label: curParent.label.trim()
                                    })
                                } else {
                                    setParent(null)
                                }
                                // setKeyParent(Math.random.toString())


                            }}
                            isLoading={categoriesLoading}
                            isDisabled={categoriesLoading}

                        />
                    </div>
                </div>


                <div className="columns">
                    <div className="column">
                        <label className="label">
                            {t("category.titleRu")}
                        </label>
                        <input className={`input is-small ${redTitleRu ? "is-danger" : ""}`}
                            value={titleRu}
                            onChange={e => setTitleRu(e.target.value)} />
                    </div>
                    <div className="column">
                        <label className="label">
                            {t("category.titleEn")}
                        </label>
                        <input className={`input is-small ${redTitleEn ? "is-danger" : ""}`}
                            value={titleEn}
                            onChange={e => setTitleEn(e.target.value)} />

                    </div>
                </div>

                <label className="label">
                    {t("category.parentCategory")}
                </label>
                <div className="field">
                    <div className="control">
                        <Select
                            options={parentCategoriesOptions}
                            isSmall={true}
                            value={parent}
                            key={keyParent}
                            onChange={target => setParent({
                                label: target.label.trim(),
                                value: target.value
                            })}
                            isLoading={categoriesLoading}
                            isDisabled={categoriesLoading}

                        />
                    </div>
                </div>
                <div className="field">
                    <div className="control">

                        <div className="columns is-centered">
                            <div className="column is-half">
                                <button className={`button is-accent-2 is-fullwidth ${loading ? "is-loading" : ""}`}
                                    onClick={() => createCategory()}>
                                    {t("category.update")}
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default CategoryUpdate