import dayjs from "dayjs"
import { useContext } from "react"
import { useState } from "react"
import Select from "../../../helpers/Select"
import { DataContext } from "../../../context/DataContext"
import { UserContext } from "../../../context/UserContext"
import { useTranslation } from "react-i18next"
import axios from "axios"
import { API_URL, growTree, nilObjectID } from "../../../utils"
import { errorMessage } from "../../../errors"
import { toast } from "react-toastify"
import { UsersContext } from "../../../context/UsersContext"

const UserItem = ({ user, index }) => {

    const [show, setShow] = useState(false)
    const { categories, categoriesOptions } = useContext(DataContext)

    const { user: { role } } = useContext(UserContext)

    const [curCategories, setCurCategories] = useState(null)


    const initCategories = categoriesOptions.filter(item => {

        return user?.allowedCategories?.includes(item?.value)

    }).map(item => {
        return {
            label: item.label.trim(),
            value: item.value
        }

    }
    )



    const curOptions = growTree([], categories, 0, 0, nilObjectID, initCategories.map(item => item.value))

    const { setNeedRefetchUsers } = useContext(UsersContext)

    const { t } = useTranslation()

    const [loading, setLoading] = useState(false)

    const updateUser = async () => {

        setLoading(true)

        const data = {}

        Object.assign(data, user)

        data['allowedCategories'] = curCategories.map(item => item.value)

        try {
            const resp = await axios.post(`${API_URL}/user/update`, data)

            const { status, message } = resp.data

            if (status === "failed") {
                setLoading(false)
                return toast.error(errorMessage(message))
            }
        } catch (error) {
            setLoading(false)
        }


        setLoading(false)
        toast.success("users.updateSuccess")
        setNeedRefetchUsers(true)
    }




    return (
        <>
            <div className="columns mb-0 pl-2">
                <div className="column is-1">{index + 1}.</div>
                <div className="column">{user.email}</div>
                <div className="column">{user.role}</div>
                <div className="column">{dayjs(user.expirationDate).format("DD/MM/YYYY - HH:mm")}</div>
                <div className="column is-1">


                    {user.role !== "admin" ? (<button
                        className="button is-small is-rounded"
                        onClick={() => {
                            setCurCategories(initCategories)
                            setShow(!show)
                        }}
                    >
                        <span className="icon is-small is-left">
                            <i
                                className={`fa ${show ? "fa-arrow-up" : "fa-arrow-down"
                                    }`}
                            ></i>
                        </span>
                    </button>) : null}

                </div>

            </div>

            {show && (user.role !== "admin") ?
                <div className="box has-background-light">

                    {/* <div className="field">
                        <div className="control"> */}

                    <div className="columns">
                        <div className="column">
                            <Select
                                options={curOptions}
                                isMulti={true}
                                isSmall={true}
                                value={curCategories}
                                onChange={targets => {

                                    setCurCategories(targets.map(item => {
                                        return {
                                            label: item.label.trim(),
                                            value: item.value
                                        }

                                    }
                                    ))
                                }
                                }
                            />
                        </div>
                        <div className="column is-one-quarter">
                            <button
                                className="button is-accent-2 is-small is-fullwidth"
                                onClick={() => updateUser()}
                            >
                                {t("users.updateCategories")}
                            </button>
                        </div>
                    </div>


                    {/* </div>
                    </div> */}
                </div>

                : null}

        </>
    )
}

export default UserItem