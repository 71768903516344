import { useLocation } from "react-router-dom"
import i18n from "./i18n"

export const responsiveMenuSize = "is-12-mobile is-4-tablet is-4-desktop is-4-widescreen is-4-fullhd"
export const responsiveContentSize = "is-12-mobile is-8-tablet is-8-desktop is-8-widescreen is-8-fullhd"

export const sp5 = <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
export const sp2 = <>&nbsp;&nbsp;</>
export const API_URL = process.env.REACT_APP_API_URL

export const validateEmail = email => {
    const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
}

export const userRoleOptions = {
    en: [
        // { label: "", value: "", disabled: true, selected: true },
        { label: "Subscriber", value: "subscriber", disabled: false, selected: false },
        { label: "Administrator", value: "admin", disabled: false, selected: false },
    ],

    ru: [
        // { label: "", value: "", disabled: true, selected: true },
        { label: "Подписчик", value: "subscriber", disabled: false, selected: false },
        { label: "Администратор", value: "admin", disabled: false, selected: false },
    ],
}


export function useQuery() {
    return new URLSearchParams(useLocation().search)
}


export const isWeakPwd = password => {
    var regExp = /(?=.*\d)(?=.*[a-zа-яё])(?=.*[A-ZА-ЯЁ]).{8,}/

    var validPassword = regExp.test(password)

    return !validPassword
}

export const isEmptyString = str => {
    var regExp = /\S/
    var validStr = regExp.test(str)

    return !validStr
}

export const nilObjectID = "000000000000000000000000"

export const growTree = (
    result,
    items,
    level,
    rootLevel,
    rootID,
    truncateIDs = [],
    disableCategories = false
) => {

    if (!(truncateIDs.includes(rootID))) {
        const curRootItems = items.filter(
            item => (item.parentID === rootID) &
                (item.id !== item.parentID)
        )



        if (curRootItems) {

            curRootItems.forEach(item => {


                const prefixSize = (level - rootLevel) * 5

                const prefix = prefixSize ? "\xa0".repeat(prefixSize) + " " : ""

                const label = i18n.language === "ru" ? item.titleRu : item.titleEn

                if (level >= rootLevel) {
                    result.push({
                        label: prefix + label,
                        value: item.id,
                        titleRu: item.titleRu,
                        titleEn: item.titleEn,
                        parentID: item.parentID,
                        isDisabled: disableCategories && item.type !== "series",
                        type: item.type
                    })
                }

                growTree(result, items, level + 1, rootLevel, item.id, truncateIDs)
            });
        } else {
            result.push({ label: "bottom", value: -1 })

        }
    }

    return result.filter(elem => (elem.value !== -1) & (!truncateIDs.includes(elem.value)))
}

export const modelTypeOptions = {
    en: [
        // { label: "", value: "", disabled: true, selected: true },
        { label: "Level", value: "level" },
        { label: "Direction", value: "dir" },
    ],

    ru: [
        // { label: "", value: "", disabled: true, selected: true },
        { label: "Уровни", value: "level" },
        { label: "Направления", value: "dir" },
    ],
}
