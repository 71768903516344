import { useContext } from "react"
import { BlogContext } from "../../../context/BlogContext"
import dayjs from "dayjs"
import Spinner from "../../../helpers/Spinner"


const Wall = () => {

    const { wallLoading, setNeedRefetchLoading } = useContext(BlogContext)
    const { posts } = useContext(BlogContext)
    return wallLoading ? <Spinner /> : <>
        <div className="columns is-centered">
            <div className="column is-8">
                {[...posts].reverse().map(post => {
                    return (
                        <article class="message is-link">
                            <div class="message-header ">
                                <p>{dayjs(post.creationDate).format("DD.MM.YYYY")}</p>
                                <button class="delete" aria-label="delete"></button>
                            </div>
                            <div class="message-body">
                                <div dangerouslySetInnerHTML={{ __html: post.text }} />
                            </div>
                        </article>
                    )
                })}
            </div>
        </div>
    </>
}

export default Wall