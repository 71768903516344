import dayjs from "dayjs"
import { sum } from "lodash"


import {
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    Bar,
    BarChart,
} from "recharts"

const BarPlot = ({ forecast }) => {

    const { values: forecastValues } = forecast
    // const output = subproduct?.lastOutputTri
    // const horizon = max(output?.values?.forecast_horizon_index)

    // const lastDate = input?.dates?.slice(-1)?.[0]

    const data = []

    for (var i = 0; i < forecastValues.length; i++) {
        // eslint-disable-next-line
        const probs = forecastValues?.[i]?.value



        data.push({
            // date: dayjs(lastDate).add(i + 1, "day").utc().unix(),
            date: forecastValues?.[i]?.timestamp,
            down: (probs[0] / sum(probs)),
            stable: (probs[1] / sum(probs)),
            up: (probs[2] / sum(probs))
        })
    }





    return true ? (
        <>
            <div className="box">
                <div className="header">
                    <h1 className="title has-text-centered">Прогноз в направлениях</h1>
                </div>
                <ResponsiveContainer aspect={window.innerWidth < 400 ? 1 : 2}>
                    <BarChart

                        data={data}

                    >
                        <CartesianGrid />
                        <XAxis
                            dataKey="date"
                            name='Date'
                            domain={["auto", "auto"]}
                            tickFormatter={(unixTime) => dayjs(unixTime).format('DD.MM')}
                        />
                        <YAxis
                            domain={[0, 1]}
                        />
                        <Tooltip formatter={(value) => `${value.toFixed(2)}`}
                            labelFormatter={(value) => dayjs(value).format('DD.MM.YYYY')}
                            itemSorter={(item) => {
                                switch (item.dataKey) {
                                    case "down":
                                        return 2;
                                    case "stable":
                                        return 1;
                                    case "up":
                                        return 0;
                                    default:
                                        return
                                }
                            }} />
                        <Legend />
                        <Bar dataKey="down" name="Вниз" fill="#0E1266" stackId="stack" >

                        </Bar>
                        <Bar dataKey="stable" name="Стабильно" fill="#0249BB" stackId="stack">

                        </Bar>
                        <Bar dataKey="up" name="Вверх" fill="#35BBBB" stackId="stack">

                        </Bar>

                    </BarChart>
                </ResponsiveContainer>
            </div>
        </>
    ) : (
        <p className="title has-text-centered has-text-grey">
            Модель ещё не была оценена
        </p>
    )
}

export default BarPlot
