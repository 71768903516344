import { createContext } from "react";
import useCategories from "./useCategories";
import useCurSeries from "./useCurSeries";
import useDataOptions from "./useDataOptions";


export const DataContext = createContext()

const DataContextProvider = ({ children }) => {




    const {
        data,
        options: dataOptions,
        loading: dataLoading,
        setNeedRefetch: setNeedRefetchData
    } = useDataOptions()

    const {
        categories,
        loading: categoriesLoading,
        setNeedRefetch: setNeedRefetchCategories,

        categoriesOptions
    } = useCategories()

    const {
        setCurSeriesID,

        curSeries,
        curForecasts,
        loading: curSeriesLoading,
        setNeedRefetch: setNeedRefetchCurSeries,

    } = useCurSeries()

    const value = {
        // All series
        data,
        dataOptions,
        dataLoading,
        setNeedRefetchData,

        // Categories
        categories,
        categoriesLoading,
        setNeedRefetchCategories,

        categoriesOptions,

        // One series
        setCurSeriesID,

        curSeries,
        curForecasts,
        curSeriesLoading,
        setNeedRefetchCurSeries
    }
    return <DataContext.Provider value={value}>{children}</DataContext.Provider>
}

export default DataContextProvider