import { useTranslation } from "react-i18next"

const Pagination = ({ page, pageCount, setPage }) => {
    const { t } = useTranslation()
    return (
        <div className="">
            <div className="columns">
                <div className="column is-one-quarter has-text-centered">
                    <button
                        className="button"
                        disabled={page <= 1}
                        onClick={() => setPage(page - 1)}
                    >
                        {t("pagination.previous")}
                    </button>
                </div>

                <div className="column is-half has-text-centered">
                    <button className="button" disabled>
                        {t("pagination.page")} {page}
                    </button>
                </div>

                <div className="column is-one-quarter has-text-centered">
                    <button
                        className="button"
                        disabled={page >= pageCount}
                        onClick={() => setPage(page + 1)}
                    >
                        {t("pagination.next")}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Pagination
