import { createContext, useEffect, useState } from "react";
import axios from "axios"
import jwt_decode from "jwt-decode";
import dayjs from "dayjs"
import { useNavigate } from "react-router-dom";



export const UserContext = createContext()




// getClaims возвращает атрибуты, считанные из токена
const getClaims = token => {
    axios.defaults.headers.common["Authorization"] = token
    const { ID, role, expiration } = jwt_decode(token)
    return { ID, role, expiration }
}

// const localStorageToken = localStorage.getItem("token") || ""
const localStorageToken = localStorage.getItem("token") || ""
const localStorageUser = localStorageToken ? getClaims(localStorageToken) : { id: null, role: "free" }

const UserContextProvider = ({ children }) => {

    const [token, setToken] = useState(localStorageToken)
    const [user, setUser] = useState(localStorageUser)
    const navigate = useNavigate()

    const logIn = createdToken => {
        localStorage.setItem("token", createdToken)

        setUser(getClaims(createdToken))
        setToken(createdToken)
    }

    const logOut = () => {
        localStorage.removeItem("token")
        setUser({})
        setToken("")
        navigate("/auth/login")

    }

    useEffect(() => {
        if (token) {
            const { expiration } = getClaims(token)

            const curTime = (new Date(dayjs().add(3, 'h')).getTime() / 1000).toFixed(0)

            if (curTime > expiration) {
                logOut()
            }

        }
        // eslint-disable-next-line
    }, [])


    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = token
    }, [token])



    const value = {
        user,
        logIn,
        logOut,
        isLogged: token.length
    }
    return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

export default UserContextProvider