import { useContext } from "react"
import { UsersContext } from "../../../context/UsersContext"
import Pagination from "../../../helpers/Pagination"
import Spinner from "../../../helpers/Spinner"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import { sp2, sp5 } from "../../../utils"
import UserItem from "./UserItem"


const UsersList = () => {

    const {
        users,
        usersLoading,
        setNeedRefetchUsers,

        page,
        setPage,
        pageCount
    } = useContext(UsersContext)

    const { t } = useTranslation()

    return usersLoading ? <Spinner /> : (

        <>

            <div className="columns mb-0 pl-2">
                <div className="column is-1"></div>
                <div className="column">{t("users.email")}</div>
                <div className="column">{t("users.role")}</div>
                <div className="column">{t("users.expiration")}</div>
                <div className="column is-1"></div>
            </div>

            {
                Object.values(users).sort((a, b) =>
                    a?.email?.localeCompare(b?.email)
                ).map((item, index) => {
                    return (
                        <>
                            <UserItem user={item} index={index} />
                            {(index < users.length) ? <hr className="m-1" /> : null}
                        </>
                    )
                })
            }

            < Pagination
                page={page}
                setPage={setPage}
                pageCount={pageCount}
            />
        </>
    )
}

export default UsersList