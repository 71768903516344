
import axios from "axios";
import { useContext } from "react";
import { useState } from "react";

import { useTranslation } from "react-i18next"
import { Link, useNavigate } from "react-router-dom";
import { DataContext } from "../context/DataContext";
import { UserContext } from "../context/UserContext";

const NavBar = ({ children }) => {

    const { t } = useTranslation()
    const [showMenu, setShowMenu] = useState(false)
    const navigate = useNavigate()

    const { isLogged, logOut } = useContext(UserContext)
    const { setNeedRefetchCategories, setNeedRefetchData } = useContext(DataContext)

    return (
        <nav className="navbar" role="navigation" aria-label="main navigation">
            <div className="navbar-brand">

                <span
                    role="button"
                    className="navbar-burger"
                    onClick={() => setShowMenu(!showMenu)}
                >
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                </span>
            </div>

            <div id="navbarBasicExample" className={"navbar-menu" + (showMenu ? " is-active" : "")}>
                <div className="navbar-start">
                    {children}
                </div>

                <div className="navbar-end">
                    <div className="navbar-item">
                        <div className="buttons">
                            {isLogged ?
                                <span
                                    className="button is-accent-2"
                                    onClick={() => {
                                        logOut()

                                        setTimeout(function () {
                                            setNeedRefetchCategories(true)
                                            setNeedRefetchData(true)
                                        }, (50));

                                        navigate("/main")
                                    }}>
                                    {t("navbar.logOut")}
                                </span>
                                :
                                <Link className="button is-accent-2" to="/auth">
                                    {t("navbar.logIn")}
                                </Link>

                            }
                        </div>
                    </div>
                </div>
            </div>
        </nav >
    );
};

export default NavBar;
