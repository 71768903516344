import axios from "axios"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { errorMessage } from "../errors"
import { API_URL } from "../utils"

const useCurSeries = () => {

    const [curSeriesID, setCurSeriesID] = useState("")
    const [curSeries, setCurSeries] = useState({})
    const [curForecasts, setCurForecasts] = useState([])
    const [loading, setLoading] = useState(false)
    const [needRefetch, setNeedRefetch] = useState(true)

    const fetchCurSeries = async () => {


        setLoading(true)
        try {

            const resp = await axios.get(`${API_URL}/data/getSeries?id=${curSeriesID}`)

            const {
                status,
                message,
                series,
                forecasts
            } = resp.data


            if (status === "failed") {
                setLoading(false)
                return toast.error(errorMessage(message))
            }

            setCurSeries(series)


            const forecastsOptions = forecasts.reduce((prev, cur) => ({ ...prev, [cur.id]: cur }), {})

            setCurForecasts(forecastsOptions)



        } catch (error) {
            setLoading(false)
            toast.error(errorMessage("sw:::000"))
        }


        setLoading(false)
    }


    useEffect(() => {
        if ((needRefetch) && (curSeriesID !== "")) {
            setNeedRefetch(false)
            setLoading(true)
            fetchCurSeries()
        }
        // eslint-disable-next-line
    }, [needRefetch])


    useEffect(() => {
        if (curSeriesID !== "") {
            setNeedRefetch(false)
            setLoading(true)
            fetchCurSeries()
        }
        // eslint-disable-next-line
    }, [curSeriesID])

    return {
        setCurSeriesID,

        curSeries,
        curForecasts,
        loading,
        setNeedRefetch

    }


}

export default useCurSeries