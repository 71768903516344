import { convertFromHTML, EditorState, ContentState } from 'draft-js';
import React, { useContext, useState } from 'react';
import Spinner from "../../../helpers/Spinner"
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useTranslation } from 'react-i18next';
import NewPost from './NewPost';
import BlogContextProvider, { BlogContext } from '../../../context/BlogContext';
import Wall from './Wall';
import { UserContext } from '../../../context/UserContext';
// import draftToHtml from 'draftjs-to-html';

const BlogView = () => {



    const { user: { role } } = useContext(UserContext)





    return <>
        <BlogContextProvider>

            {(role === "admin") ? <NewPost /> : null}

            <Wall />

        </BlogContextProvider >
    </>
}

export default BlogView