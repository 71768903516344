import { convertFromHTML, EditorState, ContentState, convertToRaw } from 'draft-js';
import React, { useContext, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useTranslation } from 'react-i18next';
import { API_URL, isEmptyString } from '../../../utils';
import draftToHtml from 'draftjs-to-html';
import { errorMessage } from '../../../errors';
import { toast } from 'react-toastify';
import axios from 'axios';
import { BlogContext } from '../../../context/BlogContext';
// import draftToHtml from 'draftjs-to-html';

const NewPost = () => {


    const emptyPost = ""
    const blocksFromHTML = convertFromHTML(emptyPost);
    const htmlState = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap,
    );

    const [state, setState] = useState(EditorState.createWithContent(htmlState))

    const { t } = useTranslation()
    const [loading, setLoading] = useState(false)

    const { setNeedRefetchWall } = useContext(BlogContext)

    const createPost = async () => {

        const postText = draftToHtml(convertToRaw(state.getCurrentContent()))

        if (postText === "<p></p>\n") {
            return toast.error(errorMessage("bl:::000"))
        }


        setLoading(true)
        try {


            const data = {
                text: postText.slice(0, -1)
            }

            const resp = await axios.post(`${API_URL}/blog/createPost`, data)

            const { status, message } = resp.data


            if (status === "failed") {
                setLoading(false)
                return toast.error(errorMessage(message))
            }

            setState(EditorState.createWithContent(htmlState))

            setLoading(false)
            setNeedRefetchWall(true)

            toast.success(t("blog.createSuccess"))
        } catch (error) {
            setLoading(false)
            toast.error(errorMessage("sw:::000"))
        }

        setLoading(false)

    }

    return <div className="columns is-centered">
        <div className="column is-8">
            <div className="form">
                <div className="field">
                    <div className="control">
                        <Editor
                            editorState={state}
                            editorStyle={{
                                "border": "1px solid",
                                "background-color": "white",
                                "height": "150px",
                                "overflow-y": "auto",
                            }}
                            wrapperClassName="wrapperClassName"
                            editorClassName="editor-class"

                            onEditorStateChange={target => {

                                setState(target)

                            }}
                        />
                    </div>
                </div>
                <div className="field">
                    <div className="control">
                        <div className={`button is-fullwidth is-accent-2 is-small ${loading ? "is-loading" : ""}`}
                            onClick={() => createPost()}>
                            {t("blog.publish")}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

}

export default NewPost