import { Navigate, Route, Routes } from "react-router-dom"
import LogInForm from "./LogInForm"
import SignUpForm from "./SignUpForm"

const Auth = () => {


    return (
        <>
            <div className="columns is-centered">
                <div className="column is-one-third has-text-centered">
                    <div className="box">
                        <Routes>
                            <Route path="" element={<Navigate to="login" />} />
                            <Route path="login" element={<LogInForm />} />
                            <Route path="signup" element={<SignUpForm />} />
                        </Routes>
                    </div>
                </div>
            </div>

        </>
    )

}

export default Auth