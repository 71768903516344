import { useState } from "react"
import { useTranslation } from "react-i18next"
import i18n from "../../../i18n"
import { API_URL, userRoleOptions, validateEmail } from "../../../utils"
import Select from "../../../helpers/Select"

import axios from "axios"
import { toast } from "react-toastify"
import { errorMessage } from "../../../errors"


const InviteNew = () => {
    const { t } = useTranslation()

    const [email, setEmail] = useState("")
    const [redEmail, setRedEmail] = useState(false)

    const [role, setRole] = useState(userRoleOptions?.[i18n.language]?.[0])
    const [loading, setLoading] = useState(false)

    const [key, setKey] = useState(Math.random())

    const sendInvite = async () => {

        if (!validateEmail(email.trim())) {
            setRedEmail(true)
            return toast.error(errorMessage("in:::000"))
        }


        setLoading(true)
        try {

            const data = {
                email,
                role: role.value
            }




            const resp = await axios.post(`${API_URL}/invite/create`, data)

            const { status, message } = resp.data


            if (status === "failed") {
                setLoading(false)
                return toast.error(errorMessage(message))
            }

            setEmail("")
            setKey(Math.random())
            setLoading(false)

            toast.success(t("invite.createSuccess"))
        } catch (error) {
            setLoading(false)
            toast.error(errorMessage("sw:::000"))
        }

        setLoading(false)

    }
    return (
        <div className="form">


            <div className="columns">
                <div className="column">
                    <div class="field">
                        <p class="control is-expanded is-fullwidth has-icons-left">

                            <input class={`input is-small  ${redEmail ? "is-danger" : ""}`}
                                key={key}
                                type="email" placeholder="Email"
                                onClick={() => setRedEmail(false)}
                                onChange={e => setEmail(e.target.value)} />

                            <span class="icon is-small is-left">
                                <i class="fa fa-envelope"></i>
                            </span>
                        </p>
                    </div>
                </div>
                <div className="column is-narrow">
                    <div class="field">
                        <div class="control has-icons-left">
                            <Select
                                options={userRoleOptions?.[i18n.language]}
                                value={role}
                                onChange={target => setRole(target)}
                                isSmall={true}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="columns has-text-centered">
                <div className="column">
                    <button className={`button is-accent-2 is-small ${loading ? "is-loading" : ""}`} onClick={() => sendInvite()}>
                        {t("invite.create")}
                    </button>
                </div>
            </div>


        </div>
    )
}

export default InviteNew