import i18n from "./i18n";

const errors = {
	ru: {
		"auth:::000": "Отказ доступа.",
		"auth:::001": "Введён некорректный Email.",
		"auth:::002": "Пароли не совпадают.",
		"auth:::003": "Пароль недостаточно надёжен.",
		"auth:::004": "Неверный логин или пароль.",


		"sw:::000": "Ошибка сервера. Перезагрузите страницу или обратитесь к администратору.",
		"sw:::001": "Ошибка базы данных. Перезагрузите страницу или обратитесь к администратору.",

		"ma:::000": "Ошибка почтового клиента. Пожалуйста, обратитесь к администратору.",

		"in:::000": "Введён некорректный Email.",
		"in:::001": "Такой пользователь уже существует.",
		"in:::002": "Некорректрый код приглашения.",
		"in:::003": "Приглашение не найдено.",
		"in:::004": "Приглашение устарело.",

		"da:::000": "Ошибка обработки файла.",
		"da:::001": "Файл не загружен.",
		"da:::002": "Файл должен иметь расширение .csv.",
		"da:::003": "Файл должен иметь расширение .py.",

		"ca:::000": "Такая категория уже существует.",
		"ca:::001": "Введите название.",
		"ca:::002": "Должна быть выбрана родительская категория.",

		"mo:::000": "Ошибка обработки файла.",

		"bl:::000": "Пост не должен быть пустым."


	},
	en: {},
};
export const errorMessage = (code) => errors[i18n.language][code];
