
import ModelUpdate from "./ModelUpdate"
import ModelList from "./ModelList"

const ModelUpdateView = () => {

    return (
        <>

            <ModelUpdate />

            <ModelList />
        </>
    )
}

export default ModelUpdateView