import { createContext } from "react";
import useWall from "./useWall";


export const BlogContext = createContext()

const BlogContextProvider = ({ children }) => {

    const {
        posts,
        loading: wallLoading,
        setNeedRefetch: setNeedRefetchWall
    } = useWall()


    const value = {
        // All series
        posts,
        wallLoading,
        setNeedRefetchWall

    }
    return <BlogContext.Provider value={value}>{children}</BlogContext.Provider>
}

export default BlogContextProvider