import { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { DataContext } from "../../context/DataContext"
import Select from "../../helpers/Select"
import Spinner from "../../helpers/Spinner"
import i18n from "../../i18n"
import { modelTypeOptions, responsiveContentSize, responsiveMenuSize } from "../../utils"
import BarPlot from "./BarPlot"
import LinePlot from "./LinePlot"


const SeriesView = () => {

    const { setCurSeriesID, curSeries,
        curForecasts, curSeriesLoading, } = useContext(DataContext)

    const forecastOptions = Object.values(curForecasts).map(item => ({
        label: item.type,
        value: item.id
    })
    )

    const { t } = useTranslation()

    const [forecastValue, setForecastValue] = useState(null)
    const [curForecast, setCurForecast] = useState({})

    const [modelOptions, setModelOptions] = useState([])
    const { seriesID } = useParams()


    const [model, setModel] = useState(null)
    const [modelType, setModelType] = useState(null)

    const [modelKey, setModelKey] = useState(Math.random())

    setCurSeriesID(seriesID)

    const setTarget = target => {
        setModelType(target)

        const baseForecast = forecastOptions?.[0]


        setForecastValue(baseForecast)
        setCurForecast(curForecasts[baseForecast?.value])

        var modelOpts
        switch (target.value) {
            case "level":
                modelOpts = Object.keys(curForecasts[baseForecast.value].levelValues["W"]).map(model => ({
                    label: model,
                    value: model
                }))
                break;
            case "dir":
                modelOpts = Object.keys(curForecasts[baseForecast.value].dirValues["W"]).map(model => ({
                    label: model,
                    value: model
                }))
                break;

            default:
                modelOpts = {}
        }


        setModelOptions(modelOpts)
        setModel(modelOpts?.[0])
        setModelKey(Math.random())

    }


    const menu = <div className="box">
        <div className="column">
            <Select
                options={modelTypeOptions[i18n.language]}
                value={modelType}
                onChange={target => setTarget(target)}
            />
        </div>

        <div className="column">
            <Select
                options={modelOptions}
                value={model}
                onChange={target => setModel(target)}
                key={modelKey}
            />
        </div>

    </div>


    const plot = () => {

        switch (modelType?.value) {
            case "level":
                return <LinePlot data={curSeries?.frequencyValues?.["W"]} forecast={curForecast?.levelValues?.["W"]?.[model?.value]} />

            case "dir":
                return <BarPlot forecast={curForecast?.dirValues?.["W"]?.[model?.value]} />

            default:
                return null
        }
    }


    const view = <>

        <div className="columns">
            <div className={`column ${responsiveMenuSize}`}>
                {menu}
            </div>
            <div className={`column ${responsiveContentSize}`}>

                {(model && modelType)
                    ? plot()
                    : null}


            </div>
        </div>

    </>

    const noSeries = <div className="text has-text-centered">
        {t("No forecasts found")}
    </div>


    return curSeriesLoading || typeof (forecastValue) === "undefined"
        ? <Spinner />
        : typeof (curForecasts) === "undefined" || Object.keys(curForecasts).length === 0
            ? noSeries
            : view
}

export default SeriesView