import { Route, Routes } from "react-router-dom"
import UsersView from "../users/UsersView"

const RestrictedArea = () => {
    return (
        <></>
    )
}

export default RestrictedArea
