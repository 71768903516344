import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

const DataMenu = () => {

    const { t } = useTranslation()
    return (
        <div className="box">

            <aside className="menu">
                <p className="menu-label">
                    {t("data.menuTitle")}
                </p>
                <ul className="menu-list">
                    <li ><Link to="update">{t("data.menuUpdate")}</Link></li>
                    <li ><Link to="categories">{t("category.menu")}</Link></li>
                </ul>

                <p className="menu-label">
                    {t("models.menuTitle")}
                </p>
                <ul className="menu-list">
                    <li ><Link to="models">{t("models.menu")}</Link></li>
                    <li ><Link to="tasks">{t("tasks.menu")}</Link></li>
                </ul>

            </aside>
        </div>
    )
}

export default DataMenu