import axios from "axios"
import { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { DataContext } from "../../../context/DataContext"
import { errorMessage } from "../../../errors"
import Select from "../../../helpers/Select"
import { API_URL, growTree, nilObjectID } from "../../../utils"

const SeriesUpdate = () => {

    const {
        data,
        dataOptions,
        dataLoading,
        setNeedRefetchData,

        categories,
        categoriesOptions,
        categoriesLoading

    } = useContext(DataContext)


    const [loading, setLoading] = useState(false)


    const updateCategoriesOptions = growTree(
        [], categories, 0, 0, nilObjectID
    )


    const [keyUpdate, setKeyUpdate] = useState(Math.random().toString())

    const { t } = useTranslation()
    const [curOption, setCurOption] = useState({})

    const [regressors, setRegressors] = useState([])

    const [description, setDescription] = useState("")
    const [name, setName] = useState("")
    const [code, setCode] = useState("")
    const [units, setUnits] = useState("")

    const [category, setCategory] = useState(null)

    const updateSeries = async () => {


        // if (isEmptyString(titleRu)) {
        //     setRedTitleRu(true)
        //     return toast.error(errorMessage("ca:::001"))
        // }

        setLoading(true)


        const data = {
            id: curOption.value,
            name,
            code,
            units,
            description,
            category: category ? category.value : nilObjectID,
            regressors: regressors.map(item => item.value)
        }



        try {
            const resp = await axios.post(`${API_URL}/data/updateSeries`, data)

            const { status, message } = resp.data

            if (status === "failed") {
                setLoading(false)
                return toast.error(errorMessage(message))
            }
        } catch (error) {
            setLoading(false)
        }

        setRegressors([])
        setCurOption({})
        setName("")
        setCode("")
        setUnits("")
        setCategory(null)
        setDescription("")

        setLoading(false)
        toast.success("data.updateSeries.updateSuccess")
        setNeedRefetchData(true)
    }


    return (
        <div className="box">

            <div className="form">
                <div className="field">
                    <div className="control">
                        <Select
                            options={dataOptions}
                            value={curOption}
                            isSmall={true}
                            onChange={target => {
                                setCurOption(target)
                                setDescription(data[target.value].description)
                                setName(data[target.value].name)
                                setCode(data[target.value].code)
                                setUnits(data[target.value].units)
                                setRegressors(data[target.value].regressors ? dataOptions.filter(item => data[target.value].regressors.includes(item.id)) : [])


                                const curParent = categoriesOptions.filter(item => item.value === data[target.value].category)?.[0]

                                if (curParent) {
                                    setCategory({
                                        label: curParent.label.trim(),
                                        value: curParent.id
                                    })
                                } else {
                                    setCategory(null)
                                }

                            }
                            }


                        />
                    </div>
                </div>

                <div className="columns">
                    <div className="column is-half">
                        <label htmlFor="" className="label">
                            {t("data.updateSeries.name")}
                        </label>
                        <div className="field">
                            <div className="control">
                                <input
                                    type="text"
                                    className="input is-small"
                                    value={name}
                                    onChange={e => setName(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="column is-one-quarter">
                        <div className="field">
                            <label htmlFor="" className="label">
                                {t("data.updateSeries.code")}
                            </label>
                            <div className="control">
                                <input
                                    type="text"
                                    className="input is-small"
                                    value={code}
                                    onChange={e => setCode(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="column is-one-quarter">
                        <div className="field">
                            <label htmlFor="" className="label">
                                {t("data.updateSeries.units")}
                            </label>
                            <div className="control">
                                <input
                                    type="text"
                                    className="input is-small"
                                    value={units}
                                    onChange={e => setUnits(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="field">
                    <label htmlFor="" className="label">
                        {t("data.updateSeries.description")}
                    </label>
                    <div className="control">
                        <textarea
                            type="text"
                            rows={3}
                            className="textarea is-small"
                            value={description}
                            onChange={e => setDescription(e.target.value)}
                        />
                    </div>
                </div>

                <div className="field">
                    <label htmlFor="" className="label">
                        {t("data.updateSeries.parentCategory")}
                    </label>
                    <div className="control">
                        <Select
                            options={updateCategoriesOptions}
                            isSmall={true}
                            value={category}
                            key={keyUpdate}
                            onChange={target => {
                                setCategory({
                                    label: target.label.trim(),
                                    value: target.value
                                })
                            }}
                            isLoading={categoriesLoading}
                            isDisabled={categoriesLoading}

                        />
                    </div>
                </div>

                <div className="field">
                    <label htmlFor="" className="label">
                        {t("data.updateSeries.regressors")}
                    </label>
                    <div className="control">
                        <Select
                            options={dataOptions.filter(item => item.id !== curOption.value)}
                            isSmall={true}
                            isMulti={true}
                            value={regressors}
                            // key={keyUpdate}
                            onChange={targets => {
                                setRegressors(targets)
                            }}

                        />
                    </div>
                </div>

                <div className="field">
                    <div className="control">
                        <button
                            className={`button is-fullwidth is-small is-accent-2 ${loading ? "is-loading" : ""}`}
                            onClick={() => updateSeries()}
                        >
                            {t("data.update")}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SeriesUpdate