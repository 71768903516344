import { Route, Routes } from "react-router-dom"
import DataView from "../data/DataView"
import UsersView from "../users/UsersView"

const SystemArea = () => {
    return (<Routes >

        <Route path="users/*" element={<UsersView />} />
        <Route path="data/*" element={<DataView />} />

    </Routes >
    )
}

export default SystemArea
