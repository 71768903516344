import { useState } from "react"
import dayjs from "dayjs"

import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
} from "recharts"

const LinePlot = ({ data, forecast }) => {

  const { values: forecastValues } = forecast
  const [showLast,] = useState(30)

  const data_raw = data?.slice(
    -showLast
  )
  // const train_dates_raw = input?.dates?.slice(-showLast)

  const train = data_raw?.reduce((prev, point, idx) => {
    const { value, timestamp } = point
    const cur_item = {
      date: dayjs.utc(timestamp).unix(),
      value: parseFloat(value.toFixed(2)),
    }

    return [...prev, cur_item]
  }, [])

  const forecastData = forecastValues?.reduce((prev, point, idx) => {
    const { value, timestamp } = point
    const cur_item = {
      date: dayjs.utc(timestamp).unix(),
      value: parseFloat(value.toFixed(2)),
    }

    return [...prev, cur_item]
  }, [])







  const series = [
    { name: "Исторические", data: train, key: "train" },
    { name: "Прогнозы", data: forecastData, key: "forecast" },
  ]


  return (


    series?.every(item => Boolean(item?.data)) ?

      <div className="box">
        <div className="header">
          <h1 className="title has-text-centered">Прогноз в уровнях</h1>
        </div>
        <div className="columns" >
          <div className="column">
            <ResponsiveContainer aspect={window.innerWidth < 400 ? 1 : 1.5}>


              <ScatterChart
                width={500}
                height={400}
                margin={{
                  top: 20,
                  right: 20,
                  bottom: 20,
                  left: 20,
                }}
              >
                <CartesianGrid />
                <XAxis
                  type="number"
                  dataKey="date"
                  domain={[series?.[0]?.data?.[0]?.date, series?.[1]?.data?.[series?.[1]?.data?.length - 1]?.date]}
                  name='Date'
                  tickFormatter={(unixTime) => dayjs.unix(unixTime).format('DD.MM.YY')}
                />
                <YAxis type="number" dataKey="value" domain={["auto", "auto"]} name="Value" />
                <Tooltip
                  formatter={(value, name, props) => {
                    if (name === "Date") {
                      return dayjs.unix(value).format('DD.MM.YY')
                    } else {
                      return value
                    }
                  }
                  }
                />
                <Legend />
                <Scatter name="Исторические" data={series?.[0]?.data} fill="#0249bb" line shape="dot" lineJointType='monotoneX' />
                <Scatter name="Прогнозные" data={series?.[1]?.data} fill="#35bbbb" line shape="dot" />
              </ScatterChart>
            </ResponsiveContainer>
          </div>

        </div>
      </div >
      : null
  )

}

export default LinePlot
