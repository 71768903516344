import CategoryNew from "./CategoryNew"
import CategoryUpdate from "./CategoryUpdate"

const CategoryView = () => {

    return (
        <>
            <CategoryNew />

            <CategoryUpdate />
        </>
    )
}

export default CategoryView