import axios from "axios"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { errorMessage } from "../errors"
import { API_URL } from "../utils"

const useWall = () => {


    const [posts, setPosts] = useState([])
    const [loading, setLoading] = useState(false)
    const [needRefetch, setNeedRefetch] = useState(true)

    const fetchWall = async () => {
        setLoading(true)
        try {
            const resp = await axios.get(`${API_URL}/blog/list`)

            const {
                status,
                message,
                posts: rawPosts
            } = resp.data

            if (status === "failed") {
                setLoading(false)
                return toast.error(errorMessage(message))
            }

            setPosts(rawPosts)

        } catch (error) {
            setLoading(false)
            toast.error(errorMessage("sw:::000"))
        }

        setLoading(false)
    }

    useEffect(() => {
        if (needRefetch) {
            setNeedRefetch(false)
            setLoading(true)
            fetchWall()
        }
        // eslint-disable-next-line
    }, [needRefetch])

    return {
        posts, loading, setNeedRefetch
    }


}

export default useWall