import { Navigate, Outlet, Route, Routes } from "react-router-dom"
import UsersContextProvider from "../../context/UsersContext"
import { responsiveContentSize, responsiveMenuSize } from "../../utils"
import InviteList from "./invites/InviteList"
import InviteNew from "./invites/InviteNew"
import UsersEditView from "./users/UsersEdit"
import UsersListView from "./users/UsersList"
import UsersMenu from "./UsersMenu"

const UsersView = () => {
    return (
        <>
            <Outlet />
            <div className="columns is-desktop">
                <div className={`column ${responsiveMenuSize}`}>
                    {/* <div className="box"> */}
                    <UsersMenu />
                    {/* </div> */}
                </div>
                <div className={`column ${responsiveContentSize}`}>

                    <div className="box">
                        <UsersContextProvider>
                            <Routes>

                                <Route path="" element={<Navigate to={"invite/new"} />} />
                                <Route path="invite/new" element={<InviteNew />} />
                                <Route path="invite/list" element={<InviteList />} />


                                <Route path="list" element={<UsersListView />} />
                                <Route path="edit" element={<UsersEditView />} />
                            </Routes>
                        </UsersContextProvider>
                    </div>
                </div>
            </div>



        </>
    )
}

export default UsersView