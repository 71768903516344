import axios from "axios"
import { useEffect } from "react"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { errorMessage } from "../errors"
import Spinner from "../helpers/Spinner"
import { API_URL, isWeakPwd, useQuery } from "../utils"

const SignUpForm = () => {
    const query = useQuery()

    const code = query.get("code")
    const [loadingValidate, setLoadingValidate] = useState(false)

    const [email, setEmail] = useState("")
    const [codeValid, setCodeValid] = useState(false)

    const [password, setPassword] = useState("")
    const [passwordRed, setPasswordRed] = useState(false)

    const [passwordRep, setPasswordRep] = useState("")
    const [passwordRepRed, setPasswordRepRed] = useState(false)

    const [loadingSignUp, setLoadingSignUp] = useState(false)

    const [role, setRole] = useState("")

    const navigate = useNavigate()

    const validateCode = async () => {
        setLoadingValidate(true)
        try {
            const resp = await axios.get(`${API_URL}/invite/validate?code=${code}`)
            const { status, email: inviteEmail, role: inviteRole } = resp.data

            setEmail(inviteEmail)
            setRole(inviteRole)

            if (status === "failed") {
                setLoadingValidate(false)
                return
            }

            setCodeValid(true)



        } catch (error) {
            setLoadingValidate(false)
            toast.error(errorMessage("sw:::000"))
        }

        setLoadingValidate(false)
    }

    useEffect(() => {
        validateCode()
        // eslint-disable-next-line
    }, [])

    const { t } = useTranslation()

    const CodeOutdated = () => {
        return (
            <div className="label has-text-grey has-text-centered">
                {t("invite.outdated")}
            </div>
        )
    }

    const signUp = async () => {



        if (isWeakPwd(password)) {
            setPasswordRed(true)
            setPasswordRepRed(true)

            return toast.error(errorMessage("auth:::003"))
        }

        if (password !== passwordRep) {

            setPasswordRed(true)
            setPasswordRepRed(true)

            return toast.error(errorMessage("auth:::002"))
        }

        setLoadingSignUp(true)
        try {

            const data = {
                email,
                password,
                code,
                role
            }


            const resp = await axios.post(`${API_URL}/user/create`, data)
            const { status, message } = resp.data


            if (status === "failed") {

                setLoadingSignUp(false)
                toast.error(errorMessage("sw:::000"))
                return
            }


            setEmail("")
            setPassword("")
            setPasswordRep("")


            setLoadingSignUp(false)

            toast.success(t("auth.createSuccess"))
            navigate('/auth/logIn')

        } catch (error) {
            setLoadingSignUp(false)
            toast.error(errorMessage("sw:::000"))
        }

        setLoadingSignUp(false)

    }

    const SignUpForm = <>
        <div className="form">
            <div className="field">
                <label className="label is-small">{t("auth.email")}</label>
                <div className="control">
                    <input type="email"
                        disabled={true}
                        value={email}
                        className="input is-small" />
                </div>
            </div>
            <div className="field">
                <label className="label is-small">{t("auth.createPassword")}</label>
                <div className="control">
                    <input
                        className={`input is-small ${passwordRed ? "is-danger" : ""}`}
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        onFocus={() => {
                            setPasswordRed(false)
                            setPasswordRepRed(false)
                        }}
                        type="password"
                    />
                </div>
            </div>

            <div className="field">
                <label className="label is-small">{t("auth.passwordRepeat")}</label>
                <div className="control">
                    <input
                        value={passwordRep}
                        onChange={e => setPasswordRep(e.target.value)}
                        onFocus={() => {
                            setPasswordRed(false)
                            setPasswordRepRed(false)
                        }}
                        type="password"
                        className={`input is-small ${passwordRepRed ? "is-danger" : ""}`}
                    />
                </div>
            </div>

            <button
                className={`button is-accent-2 ${loadingSignUp ? "is-loading" : ""}`}
                onClick={() => signUp()}
            >{t("auth.signUp")}</button>
        </div>
    </>


    return loadingValidate ? <Spinner /> : codeValid ? SignUpForm : <CodeOutdated />
}

export default SignUpForm