import axios from "axios"
import { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { DataContext } from "../context/DataContext"
import { UserContext } from "../context/UserContext"
import { errorMessage } from "../errors"
import { API_URL } from "../utils"

const LogInForm = () => {

    const { t } = useTranslation()
    const navigate = useNavigate()

    const { logIn } = useContext(UserContext)

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")

    const [loadingSignIn, setLoadingSignIn] = useState(false)


    const { setNeedRefetchCategories, setNeedRefetchData } = useContext(DataContext)

    const signIn = async () => {

        setLoadingSignIn(true)
        try {

            const data = {
                email,
                password
            }


            const resp = await axios.post(`${API_URL}/auth/logIn`, data)
            const { status, message, jwt: token } = resp.data


            if (status === "failed") {
                setLoadingSignIn(false)
                toast.error(errorMessage(message))
                return
            }

            logIn(token)

            setEmail("")
            setPassword("")


            setLoadingSignIn(false)


            setNeedRefetchCategories(true)
            setNeedRefetchData(true)
            navigate('/main')

        } catch (error) {
            setLoadingSignIn(false)
            toast.error(errorMessage("sw:::000"))
        }

    }

    return (
        <div className="form">
            <div className="field">
                <label className="label is-small">{t("auth.email")}</label>
                <div className="control">
                    <input
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        type="email"
                        placeholder="example@gmail.com"
                        className="input is-small" />
                </div>
            </div>
            <div className="field">
                <label className="label is-small">{t("auth.password")}</label>
                <div className="control">
                    <input
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        type="password"
                        className="input is-small" />
                </div>
            </div>

            <button
                className={`button is-accent-2 ${loadingSignIn ? "is-loading" : ""}`}
                onClick={
                    () => {
                        signIn()

                    }
                }
            >{t("auth.logIn")}
            </button>
        </div>
    )
}

export default LogInForm