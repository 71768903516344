import { Navigate, Route, Routes } from "react-router-dom"
import Auth from "../../auth/Auth"
import BlogView from "../main/blog/BlogView"
import MainView from "../main/MainView"
import SeriesView from "../main/SeriesView"


const UnrestrictedArea = () => {
    return (
        <Routes >
            <Route path="/" element={<Navigate to={"/main"} />} />

            <Route path="/main" element={<MainView />} />

            <Route path="/blog" element={<BlogView />} />

            <Route path="/main/:seriesID" element={<SeriesView />} />


            <Route path="/auth/*" element={<Auth />} />
        </Routes>
    )
}

export default UnrestrictedArea
