import Select from 'react-select';

import _ from "lodash"
import { useTranslation } from 'react-i18next';

const MySelect = ({
    options,
    onChange,
    value,
    defaultValue,
    isLoading,
    isDisabled,
    isSmall = false,
    isMulti = false
}) => {

    const { t } = useTranslation()

    var customStyles = {}

    if (isSmall) {

        const smallStyles = {
            container: provided => ({
                ...provided,
                width: 'auto'
            }),

            control: (provided, state) => ({
                ...provided,
                minHeight: '30px',
                height: 'auto',
                boxShadow: state.isFocused ? null : null,
            }),


            valueContainer: (provided, state) => ({
                ...provided,
                height: 'auto',
                padding: '0 6px'
            }),

            input: (provided, state) => ({
                ...provided,
                margin: '0px',
            }),
            indicatorSeparator: state => ({
                display: 'none',
            }),
            indicatorsContainer: (provided, state) => ({
                ...provided,
                height: '30px',
            }),
            // menu: (base) => ({
            //     ...base,
            //     width: "max-content",
            //     minWidth: "100%"
            // }),
        };
        customStyles = _.extend(customStyles, smallStyles)

    }
    return <Select
        options={options}
        onChange={onChange}
        value={value}
        defaultValue={defaultValue}
        styles={customStyles}
        placeholder={t("selector.placeholder")}
        isLoading={isLoading}
        isDisabled={isDisabled}
        className={`${isMulti ? "basic-multi-select" : ""}`}
        isMulti={isMulti ? "isMulti" : ""}
    />
}

export default MySelect