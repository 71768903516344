import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

const UsersMenu = () => {

    const { t } = useTranslation()
    return (
        <div className="box">

            <aside className="menu">
                <p className="menu-label">
                    {t("invite.menuTitle")}
                </p>
                <ul className="menu-list">
                    <li ><Link to="invite/new">{t("invite.menuNew")}</Link></li>
                    <li><Link to="invite/list"> {t("invite.menuList")}</Link></li>
                </ul>
                <p className="menu-label">
                    {t("users.menuTitle")}
                </p>

                <ul className="menu-list">
                    <li><Link to="list">{t("users.menuList")}</Link></li>
                    <li><Link to="edit">{t("users.menuEdit")}</Link></li>
                </ul>

            </aside>
        </div>
    )
}

export default UsersMenu